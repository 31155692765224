.groupChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  overflow-x: hidden;
  width: 115px;
  height: 50px;
}
.rivers {
  position: absolute;
  top: 6px;
  left: 10px;
}
.rectangleParent {
  position: relative;
  width: 115px;
  height: 50px;
  flex-shrink: 0;
}
.groupItem {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--color-gray);
  width: 169px;
  height: 50px;
}
.rectangleGroup {
  position: relative;
  width: 169px;
  height: 50px;
  flex-shrink: 0;
}
.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--color-gray);
  width: 207px;
  height: 50px;
}
.rectangleContainer {
  position: relative;
  width: 207px;
  height: 50px;
  flex-shrink: 0;
}

.groupParent,
.namePanelAndFilter {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.groupParent {
  flex: 1;
  justify-content: flex-start;
}

.topFrame {
  width: 100%;
  height: 100%;
  min-height: 50px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
  overflow: hidden;
  /* transition: height cubic-bezier(.33,-0.76,.4,1.32) 1s; */

  background: linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -moz-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#CFFFDB", endColorstr="#A6DC9A", GradientType=1 );
  /* border-top: 10px solid var(--color-black); */
  border-right: 10px solid var(--color-black);
  /* border-bottom: 10px solid var(--color-black); */
  overflow-x: hidden;
  width: 100%;
}
