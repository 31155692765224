@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap');

html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  height: 100%;
  line-height: normal;
  overflow-x: hidden;
  background-color: #172925;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-left: 10px solid var(--color-black);
  border-bottom: 10px solid var(--color-black);
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 12px;
  border-radius: 5px;
  /* background-clip: padding-box; */
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid transparent;
}

*::-webkit-scrollbar-track {
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}

*::-webkit-scrollbar-thumb {
  color: #fff;
  box-shadow: inset 0 0 0 5px;
}

/* div:hover {
  color: rgba(0, 0, 0, 0.3);
} */
#root {
  height: 100%;
  width: 100%;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 32px;
  --font-size-lg: 64px;

  /* Colors */
  --color-black: #000;
  --color-gray: #94f2b6;
  /*#d9d9d9;*/

  /* Paddings */
  --padding-md: 0 25px 25px 25px;

  /* border radiuses */
  --br-md: 3px;
}

p {
  font-family: 'Karla', sans-serif;
}

.pheader {
  font-weight: 400;
  font-style: italic;
  font-size: 1.3rem;
}

.pbody {
  font-weight: 300;
  font-size: 1.1rem;
}
