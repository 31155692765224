.projectDisplay {
  /* width: 65%; */
  /* max-width: 1000px; */
  padding: 25px;
  font-family: var(--font-inter);
  color: white;
  /* overflow-y: scroll; */
  /* position: absolute;
    left: 0; */
  width: calc(50% - 25px);
}

.emptyProjectDisplay {
  width: 50%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.ProjectDisplayImage {
  width: calc(100% - 20px);
  max-height: 160px;
  object-fit: cover;
  object-position: 0%;
  border-radius: 10px;
}

.ProjectDisplayTitle {
  font-size: 2rem;
  margin: 20px 0;
}

.ProjectDisplayDate {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.ProjectDisplayShortText {
  font-size: 1.1rem;
  /* max-width: 500px; */
  font-weight: 200;
  margin-top: 0;
  margin-right: 20px;
}

.displayTop {
}

.ProjectDisplayLongText {
  font-size: 1rem;
  /* max-width: 500px; */
  font-weight: 200;
  margin-top: 0;
}

.ProjectDisplayBottom {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ProjectDisplayLeft {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 30px 0 0;
  transform: width ease-out 0.5s;
}

.ProjectDisplayRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 25px 20px 0 0;
}

.ProjectDisplayTabHolder {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  color: black;
}

.ProjectDisplayTagTitle {
  font-size: 1.1rem;
  font-weight: 300;
  margin: 20px 0 10px 0;
}
