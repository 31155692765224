.homepageChild {
  /* border-right: 10px solid var(--color-black); */
  /* border-bottom: 10px solid var(--color-black); */
  /* border-left: 10px solid var(--color-black); */
  box-sizing: border-box;
  width: 100%;
  /* min-height: 800px; */
  flex: 1;
  flex-shrink: 0;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
  /* padding: var(--padding-md); */
  /* transition: height cubic-bezier(.9,-0.22,.18,1.14) 1s; */
  background-color: #172925;
  display: flex;
  /* flex-direction: row; */
  color: white;
  position: relative;
}
