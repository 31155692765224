@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300&display=swap);
.TopFrame_groupChild__3xDHu {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  overflow-x: hidden;
  width: 115px;
  height: 50px;
}
.TopFrame_rivers__3q2gJ {
  position: absolute;
  top: 6px;
  left: 10px;
}
.TopFrame_rectangleParent__3NtlV {
  position: relative;
  width: 115px;
  height: 50px;
  flex-shrink: 0;
}
.TopFrame_groupItem__12c0L {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--color-gray);
  width: 169px;
  height: 50px;
}
.TopFrame_rectangleGroup__1d-nS {
  position: relative;
  width: 169px;
  height: 50px;
  flex-shrink: 0;
}
.TopFrame_groupInner__11kSy {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-md);
  background-color: var(--color-gray);
  width: 207px;
  height: 50px;
}
.TopFrame_rectangleContainer__11VKY {
  position: relative;
  width: 207px;
  height: 50px;
  flex-shrink: 0;
}

.TopFrame_groupParent__Ff39D,
.TopFrame_namePanelAndFilter__1O2Vq {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TopFrame_groupParent__Ff39D {
  flex: 1 1;
  justify-content: flex-start;
}

.TopFrame_topFrame__3Xuav {
  width: 100%;
  height: 100%;
  min-height: 50px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
  overflow: hidden;
  /* transition: height cubic-bezier(.33,-0.76,.4,1.32) 1s; */

  background: linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#CFFFDB", endColorstr="#A6DC9A", GradientType=1 );
  /* border-top: 10px solid var(--color-black); */
  border-right: 10px solid var(--color-black);
  /* border-bottom: 10px solid var(--color-black); */
  overflow-x: hidden;
  width: 100%;
}

.InfoPage_infoPageBody__3V0qj {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.InfoPage_viewerFeature__1Fq7B {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 50px;
  grid-gap: 0 50px;
  gap: 0 50px;
  /* margin-left: 70px; */
  -webkit-animation: InfoPage_fadein__1d0jd ease-out 1s 1s;
          animation: InfoPage_fadein__1d0jd ease-out 1s 1s;
}

.InfoPage_aboutText__1PhgL {
  /* margin-right: 50px; */
}

.InfoPage_viewerFeature__1Fq7B p {
  font-family: 'Karla', sans-serif;
}

.InfoPage_profileImage__1IG5j {
  width: 450px;
  border-radius: 10px;
}

.InfoPage_pheader__2aO8G {
  font-weight: 400;
  font-style: italic;
  font-size: 1.3rem;
}

.InfoPage_pbody__X9DI1 {
  font-weight: 300;
  font-size: 1rem;
}

.About_topFrameChild__2sksb {
  align-self: stretch;
  /* flex: 1; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsla(135, 100%, 91%, 1);

  background: linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#CFFFDB", endColorstr="#A6DC9A", GradientType=1 );
  border-top: 10px solid var(--color-black);

  border-bottom: 10px solid var(--color-black);
  /* overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;*/
}

.Tab_tab__3-hDu {
    width: auto;
    padding: 5px;
}

.Tab_tabH4__1-k4_ {
    margin: 0;
    font-size: 0.8rem;
    text-transform: capitalize;
}

.Tab_tabBody__3lxyn {
    border-radius: 3px;
    background-color: var(--color-gray);
    padding: 10px;
}

.Tab_tabHolder__2xOgd {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 20px;
    gap: 20px;
    max-width: 414px;
    padding: 10px 0;
}
.LandingPanel_namePanelAndFilter__3W7Rf {
  /* border: 10px solid var(--color-black); */
  padding: 0 0 0 25px;
  padding-right: 0;
  justify-content: center;
  /* flex: 0; */
  /* max-width: calc(100% - 100px);
    min-width: 400px; */
  /* align-self: stretch; */
  position: relative;
  overflow-x: hidden;
  height: 100%;
  border-top: 10px solid var(--color-black);
  border-right: 10px solid var(--color-black);
  border-bottom: 10px solid var(--color-black);
  box-sizing: border-box;
  background-color: white;
}

.LandingPanel_info__2x0sF {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  z-index: 2;
  /* margin-right: 30px; */
  overflow-y: scroll;
  max-width: 400px;
  justify-content: center;
}

.LandingPanel_title__2l6oV {
  position: relative;
  font-size: var(--font-size-lg);
  display: inline-block;
  width: 207px;
  height: 156px;
  flex-shrink: 0;
  -webkit-text-stroke: 1px #000;
}

.LandingPanel_isASound__luKOv {
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
}

.LandingPanel_isASoundContainer__2A6bj {
  position: relative;
  display: inline-block;
  width: calc(100% - 50px);

  flex-shrink: 0;
}

.LandingPanel_titleAndIconList__XxdFs {
  display: flex;
  flex-direction: row;
}

.LandingPanel_iconList__10JWG {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.LandingPanel_icon__2mROO {
  height: 48px;
  -webkit-filter: saturate(1) sepia(800%) hue-rotate(100deg) saturate(2) contrast(160%)
    brightness(95%);
          filter: saturate(1) sepia(800%) hue-rotate(100deg) saturate(2) contrast(160%)
    brightness(95%);
}

/* CSS */
.LandingPanel_hireMe__2Zf7O {
  align-items: center;
  -webkit-appearance: none;
          appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, -webkit-transform 0.15s;
  transition: box-shadow 0.15s, transform 0.15s;
  transition: box-shadow 0.15s, transform 0.15s, -webkit-transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.LandingPanel_hireMeContainer__1HKtN {
  width: calc(100% - 50px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

/* CSS */
.LandingPanel_hireMe__2Zf7O {
  background-color: #defae7;
  border: 2px solid #00421b;

  border-radius: 30px;
  box-shadow: #00421b 4px 4px 0 0;
  color: #00421b;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  min-width: 140px;
}

.LandingPanel_hireMe__2Zf7O:hover {
  background-color: #fff;
}

.LandingPanel_hireMe__2Zf7O:active {
  box-shadow: #00421b 2px 2px 0 0;
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}

.LandingPanel_expandUp__uS9cw {
  position: absolute;
  bottom: 0;
  width: 50px;
  left: calc(50% - 25px);
}

.modal_modalBodyContainer__3ZFeL {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  transition: opacity ease-out 0.2s;
  left: 0;
  top: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border-bottom: 10px solid var(--color-black);
}

.modal_modalBody__30AjQ {
  min-width: 300px;
  width: 300px;
  height: 20%;
  min-height: 350px;
  background-color: #009a40;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  box-shadow: #00230e 4px 4px 0 0;
}

/* CSS */
.modal_hireMe__3WLPg {
  background-color: #defae7;
  border: 2px solid #00421b;

  border-radius: 30px;
  box-shadow: #00421b 4px 4px 0 0;
  color: #00421b;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.modal_hireMe__3WLPg:hover {
  background-color: #fff;
}

.modal_hireMe__3WLPg:active {
  box-shadow: #00421b 2px 2px 0 0;
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .modal_hireMe__3WLPg {
    min-width: 120px;
    padding: 0 25px;
  }
}

.modal_thankyou__y8J38 {
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
  right: 30px;
  top: calc(50% - 99px);
}

.modal_helloandthankyou__1gl5P {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.modal_closeButton__2R2qV {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: white;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: larger;
}

.HomePage_homepage__17Rpb {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* overflow-y: hidden; */
}

.HomePage_modalBodyContainer__2LsuT {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: opacity ease-out 0.2s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.HomePage_modalBody__n-sJi {
  min-width: 300px;
  width: 300px;
  height: 20%;
  min-height: 350px;
  background-color: #009a40;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  box-shadow: #00230e 4px 4px 0 0;
}

.HomePage_link__2GE8B {
  font-family: 'Karla', sans-serif;
  color: white;
  font-size: 1.3rem;
}

.BottomFrame_homepageChild__2sQci {
  /* border-right: 10px solid var(--color-black); */
  /* border-bottom: 10px solid var(--color-black); */
  /* border-left: 10px solid var(--color-black); */
  box-sizing: border-box;
  width: 100%;
  /* min-height: 800px; */
  flex: 1 1;
  flex-shrink: 0;
  font-size: var(--font-size-base);
  color: var(--color-black);
  font-family: var(--font-inter);
  /* padding: var(--padding-md); */
  /* transition: height cubic-bezier(.9,-0.22,.18,1.14) 1s; */
  background-color: #172925;
  display: flex;
  /* flex-direction: row; */
  color: white;
  position: relative;
}

.ProjectDisplay_projectDisplay__2ovsC {
  /* width: 65%; */
  /* max-width: 1000px; */
  padding: 25px;
  font-family: var(--font-inter);
  color: white;
  /* overflow-y: scroll; */
  /* position: absolute;
    left: 0; */
  width: calc(50% - 25px);
}

.ProjectDisplay_emptyProjectDisplay__wUSm1 {
  width: 50%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.ProjectDisplay_ProjectDisplayImage__yUwuV {
  width: calc(100% - 20px);
  max-height: 160px;
  object-fit: cover;
  object-position: 0%;
  border-radius: 10px;
}

.ProjectDisplay_ProjectDisplayTitle__zQZN- {
  font-size: 2rem;
  margin: 20px 0;
}

.ProjectDisplay_ProjectDisplayDate__3Hndo {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.ProjectDisplay_ProjectDisplayShortText__3O8i- {
  font-size: 1.1rem;
  /* max-width: 500px; */
  font-weight: 200;
  margin-top: 0;
  margin-right: 20px;
}

.ProjectDisplay_displayTop__1by-W {
}

.ProjectDisplay_ProjectDisplayLongText__163Rm {
  font-size: 1rem;
  /* max-width: 500px; */
  font-weight: 200;
  margin-top: 0;
}

.ProjectDisplay_ProjectDisplayBottom__oFbx_ {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ProjectDisplay_ProjectDisplayLeft__QEmVY {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 30px 0 0;
  -webkit-transform: width ease-out 0.5s;
          transform: width ease-out 0.5s;
}

.ProjectDisplay_ProjectDisplayRight__2BpwQ {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 25px 20px 0 0;
}

.ProjectDisplay_ProjectDisplayTabHolder__btbwg {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
  color: black;
}

.ProjectDisplay_ProjectDisplayTagTitle__18in8 {
  font-size: 1.1rem;
  font-weight: 300;
  margin: 20px 0 10px 0;
}

.ProjectList_ProjectListContainer__eIItk {
  /* width: 35%; */
  /* margin-right: 20px; */
  /* right: 0; */
  /* width: 50%; */
  padding: 25px 0;
  height: 100%;
  background-color: rgb(10 21 11);
  /* position: absolute; */
}

.ProjectList_ProjectList__37e4Q {
  /* width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
    gap: 5px; */
  overflow-x: hidden;
  /* padding: 25px; */
}
.ProjectList_ProjectTable__8CaG7 {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 15px;
}

.ProjectList_ProjectListItem__30DhX {
  width: 100%;
  /* height: 40px; */
  margin: 5px 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  transition: margin ease-out 0.3s;
}

.ProjectList_ProjectListItemSelected__1625U {
  width: 100%;
  /* height: 40px; */
  /* margin: 5px 0; */
  padding-left: 25px;
  text-align: left;
  display: flex;
  flex-direction: row;
  background-color: #172925;
  transition: margin ease-out 0.3s;
  font-size: 0.5rem;
}

.ProjectList_ProjectListItemDate__4tToB {
  margin: 0 0 0 25px;
  /* position: absolute; */
  left: 30px;
  width: 90px;
  text-align: justify;
  font-size: 0.75rem;
}

.ProjectList_tableTitle__25TIT {
  /* position: absolute; */
  /* right: 30px; */
  font-size: 0.75rem;
  text-overflow: clip;
  white-space: nowrap;
  overflow-x: hidden;
  min-width: 300px;
  width: auto;
}

.ProjectList_ProjectListButton__3fJOQ {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-size: 1.05rem;
  font-weight: 100;
  font-family: 'Karla', sans-serif;
  color: white;
  padding: 0;
}

.ProjectList_ProjectListButton__3fJOQ p {
  text-indent: -20px;
  padding: 0 0 0 20px;
  font-weight: 200;
}

.ProjectList_ProjectListTitle__2pBNJ {
  margin: 0 0 10px;
  padding-left: 15px;
  width: 100%;
  text-align: left;
}

html {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  height: 100%;
  line-height: normal;
  overflow-x: hidden;
  background-color: #172925;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-left: 10px solid #000;
  border-left: 10px solid var(--color-black);
  border-bottom: 10px solid #000;
  border-bottom: 10px solid var(--color-black);
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 12px;
  border-radius: 5px;
  /* background-clip: padding-box; */
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid transparent;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
  background-color: var(--scroll-track, transparent);
  border-radius: var(--scroll-radius);
  border-radius: var(--scroll-track-radius, var(--scroll-radius));
}

*::-webkit-scrollbar-thumb {
  color: #fff;
  box-shadow: inset 0 0 0 5px;
}

/* div:hover {
  color: rgba(0, 0, 0, 0.3);
} */
#root {
  height: 100%;
  width: 100%;
}
:root {
  /* fonts */
  --font-inter: Inter;

  /* font sizes */
  --font-size-base: 32px;
  --font-size-lg: 64px;

  /* Colors */
  --color-black: #000;
  --color-gray: #94f2b6;
  /*#d9d9d9;*/

  /* Paddings */
  --padding-md: 0 25px 25px 25px;

  /* border radiuses */
  --br-md: 3px;
}

p {
  font-family: 'Karla', sans-serif;
}

.pheader {
  font-weight: 400;
  font-style: italic;
  font-size: 1.3rem;
}

.pbody {
  font-weight: 300;
  font-size: 1.1rem;
}

