.topFrameChild {
  align-self: stretch;
  /* flex: 1; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsla(135, 100%, 91%, 1);

  background: linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -moz-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  background: -webkit-linear-gradient(
    225deg,
    hsla(135, 100%, 91%, 1) 0%,
    hsla(109, 49%, 73%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#CFFFDB", endColorstr="#A6DC9A", GradientType=1 );
  border-top: 10px solid var(--color-black);

  border-bottom: 10px solid var(--color-black);
  /* overflow-x: hidden;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;*/
}
