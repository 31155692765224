.namePanelAndFilter {
  /* border: 10px solid var(--color-black); */
  padding: 0 0 0 25px;
  padding-right: 0;
  justify-content: center;
  /* flex: 0; */
  /* max-width: calc(100% - 100px);
    min-width: 400px; */
  /* align-self: stretch; */
  position: relative;
  overflow-x: hidden;
  height: 100%;
  border-top: 10px solid var(--color-black);
  border-right: 10px solid var(--color-black);
  border-bottom: 10px solid var(--color-black);
  box-sizing: border-box;
  background-color: white;
}

.info {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100%;
  z-index: 2;
  /* margin-right: 30px; */
  overflow-y: scroll;
  max-width: 400px;
  justify-content: center;
}

.title {
  position: relative;
  font-size: var(--font-size-lg);
  display: inline-block;
  width: 207px;
  height: 156px;
  flex-shrink: 0;
  -webkit-text-stroke: 1px #000;
}

.isASound {
  margin-block-start: 0;
  margin-block-end: 0;
}

.isASoundContainer {
  position: relative;
  display: inline-block;
  width: calc(100% - 50px);

  flex-shrink: 0;
}

.titleAndIconList {
  display: flex;
  flex-direction: row;
}

.iconList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.icon {
  height: 48px;
  filter: saturate(1) sepia(800%) hue-rotate(100deg) saturate(2) contrast(160%)
    brightness(95%);
}

/* CSS */
.hireMe {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: 'JetBrains Mono', monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.hireMeContainer {
  width: calc(100% - 50px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 50px;
}

/* CSS */
.hireMe {
  background-color: #defae7;
  border: 2px solid #00421b;

  border-radius: 30px;
  box-shadow: #00421b 4px 4px 0 0;
  color: #00421b;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  min-width: 140px;
}

.hireMe:hover {
  background-color: #fff;
}

.hireMe:active {
  box-shadow: #00421b 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.expandUp {
  position: absolute;
  bottom: 0;
  width: 50px;
  left: calc(50% - 25px);
}
