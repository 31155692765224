.modalBodyContainer {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;
  transition: opacity ease-out 0.2s;
  left: 0;
  top: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  border-bottom: 10px solid var(--color-black);
}

.modalBody {
  min-width: 300px;
  width: 300px;
  height: 20%;
  min-height: 350px;
  background-color: #009a40;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  box-shadow: #00230e 4px 4px 0 0;
}

/* CSS */
.hireMe {
  background-color: #defae7;
  border: 2px solid #00421b;

  border-radius: 30px;
  box-shadow: #00421b 4px 4px 0 0;
  color: #00421b;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.hireMe:hover {
  background-color: #fff;
}

.hireMe:active {
  box-shadow: #00421b 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .hireMe {
    min-width: 120px;
    padding: 0 25px;
  }
}

.thankyou {
  border-radius: 20px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  position: absolute;
  right: 30px;
  top: calc(50% - 99px);
}

.helloandthankyou {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.closeButton {
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: white;
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-size: larger;
}
