.homepage {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* overflow-y: hidden; */
}

.modalBodyContainer {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: opacity ease-out 0.2s;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.modalBody {
  min-width: 300px;
  width: 300px;
  height: 20%;
  min-height: 350px;
  background-color: #009a40;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  box-shadow: #00230e 4px 4px 0 0;
}

.link {
  font-family: 'Karla', sans-serif;
  color: white;
  font-size: 1.3rem;
}
