.ProjectListContainer {
  /* width: 35%; */
  /* margin-right: 20px; */
  /* right: 0; */
  /* width: 50%; */
  padding: 25px 0;
  height: 100%;
  background-color: rgb(10 21 11);
  /* position: absolute; */
}

.ProjectList {
  /* width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
    gap: 5px; */
  overflow-x: hidden;
  /* padding: 25px; */
}
.ProjectTable {
  width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 15px;
}

.ProjectListItem {
  width: 100%;
  /* height: 40px; */
  margin: 5px 0;
  text-align: left;
  display: flex;
  flex-direction: row;
  transition: margin ease-out 0.3s;
}

.ProjectListItemSelected {
  width: 100%;
  /* height: 40px; */
  /* margin: 5px 0; */
  padding-left: 25px;
  text-align: left;
  display: flex;
  flex-direction: row;
  background-color: #172925;
  transition: margin ease-out 0.3s;
  font-size: 0.5rem;
}

.ProjectListItemDate {
  margin: 0 0 0 25px;
  /* position: absolute; */
  left: 30px;
  width: 90px;
  text-align: justify;
  font-size: 0.75rem;
}

.tableTitle {
  /* position: absolute; */
  /* right: 30px; */
  font-size: 0.75rem;
  text-overflow: clip;
  white-space: nowrap;
  overflow-x: hidden;
  min-width: 300px;
  width: auto;
}

.ProjectListButton {
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  font-size: 1.05rem;
  font-weight: 100;
  font-family: 'Karla', sans-serif;
  color: white;
  padding: 0;
}

.ProjectListButton p {
  text-indent: -20px;
  padding: 0 0 0 20px;
  font-weight: 200;
}

.ProjectListTitle {
  margin: 0 0 10px;
  padding-left: 15px;
  width: 100%;
  text-align: left;
}
