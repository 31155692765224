.infoPageBody {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewerFeature {
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 50px;
  gap: 0 50px;
  /* margin-left: 70px; */
  animation: fadein ease-out 1s 1s;
}

.aboutText {
  /* margin-right: 50px; */
}

.viewerFeature p {
  font-family: 'Karla', sans-serif;
}

.profileImage {
  width: 450px;
  border-radius: 10px;
}

.pheader {
  font-weight: 400;
  font-style: italic;
  font-size: 1.3rem;
}

.pbody {
  font-weight: 300;
  font-size: 1rem;
}
