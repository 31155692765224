.tab {
    width: auto;
    padding: 5px;
}

.tabH4 {
    margin: 0;
    font-size: 0.8rem;
    text-transform: capitalize;
}

.tabBody {
    border-radius: 3px;
    background-color: var(--color-gray);
    padding: 10px;
}

.tabHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 414px;
    padding: 10px 0;
}